// i18next-extract-mark-ns-start support

import { ISupport } from '@utils/types';
import { graphql } from 'gatsby';
import React from 'react';

import Banner from '@components/Banner';
import Breadcrumbs, { Breadcrumb } from '@components/Breadcrumbs';
import Deck from '@components/Deck';
import PageSection from '@components/PageSection';
import Tile from '@components/Tile';
import Page from '@components/layout/Page';
import SEO from '@components/layout/SEO';
import { useTranslation } from 'gatsby-plugin-react-i18next';

const supportPage = ({
	pageContext: {
		breadcrumb: { crumbs },
	},
	data
}: {
	pageContext: {
		breadcrumb: { crumbs: Breadcrumb[] };
	};
	data: any;
}) => {
	const { t } = useTranslation();

	return (
		<Page>
			<SEO
				title={t("seo_title")}
				description={t("seo_description")}
			/>

			<Banner
				title={t("title")}
				image={{
					src: data.banner.childImageSharp.gatsbyImageData,
					alt: '',
				}}
			/>

			<Breadcrumbs crumbs={crumbs} />

			<PageSection
				className="section__tiles  section--padding--adjust"
				layout="wide">
				<Deck>
					{data.support.edges.map(
						({ node }: { node: ISupport }, i: number) => (
							<Tile
								key={i}
								title={t(node.titlekey) /* i18next-extract-disable-line */}
								image={{
									src: node.image,
									alt: t(node.titlekey) /* i18next-extract-disable-line */,
								}}
								link={node.link}>
								{t(node.titlekey) /* i18next-extract-disable-line */}
							</Tile>
						)
					)}
				</Deck>
			</PageSection>
		</Page >
	);
};

export default supportPage;

export const pageQuery = graphql`
	query ($language: String!) {
		locales: allLocale(
			filter: { ns: { in: ["support", "_common"] }, language: { eq: $language } }
		) {
			edges {
				node {
				ns
				data
					language
				}
			}
		}
		banner: file(relativePath: { eq: "content/software-hero.jpg" }) {
			...imageBreaker
		}
		support: allSupportJson {
			edges {
				node {
					titlekey
					image {
						...imageTile
					}
					link
				}
			}
		}
	}
`;
